@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Inter, system-ui, sans-serif;
  }
}

button {
  @apply disabled:cursor-not-allowed;
}

button:disabled {
  opacity: .5;
}

.pac-container.hdpi {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button.react-time-picker__button {
  display: none !important;
}

.rdp,
.rdp .rdp-months,
.rdp .rdp-month,
.rdp .rdp-table,
.rdp .rdp-caption {
  width: 100% !important;
  max-width: 100% !important;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  background-color: #003680 !important;
}

h2.rdp-caption_label {
  color: #003680 !important;
}

.react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour,
.react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute {
  width: 20px !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@layer utilities {
  .header-font {
    font-family: "Work Sans";
  }

  .checkout-page .address svg * {
    stroke: #fff;
  }


  .pp-card-icon svg *,
  .no-live-schedule svg * {
    stroke: #292929;
  }
  .svg-menu-item:hover svg * {
    stroke: #009fe3;
  }
  .svg-menu-special svg * {
    fill: #fff;
    stroke: #009fe3;
  }
  .svg-menu-special:hover svg * {
    fill: #ffffff00;
    stroke: #009fe3;
  }
  .svg-icon-white * {
    stroke: #ffffff;
  }
  .svg-icon-regular * {
    stroke: #656565;
  }
  .account-menu-container {
    min-height: calc(100vh - 100px);
  }
  .highlighted-menu-item svg * {
    stroke: #009fe3;
  }
  .highlighted-menu-item {
    font-weight: 500;
  }
  #sidebar-lg::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 250px;
    height: 100%;
    opacity: 0.2;
    background-image: url("./assets/images/ruumies-logo-pattern.png");
    background-repeat: space;
  }
}
